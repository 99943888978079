import $ from 'jquery';

var duration_stash = "1-week"

$(document).ready(function() {
    // Load the Visualization API and the piechart package.
    google.charts.load('current', {'packages': ['corechart']});
    // Set a callback to run when the Google Visualization API is loaded.
    google.charts.setOnLoadCallback(drawChart);

    $('#motion-chart-day').on('click', () => drawChart('1-day') );
    $('#motion-chart-3-days').on('click', () => drawChart('3-days') );
    $('#motion-chart-week').on('click', () => drawChart('1-week') );
    $('#motion-chart-month').on('click', () => drawChart('1-month') );
    $('#motion-chart-3-months').on('click', () => drawChart('3-months') );
    $('#motion-chart-go').on('click', (e) => { e.preventDefault(); drawChart('custom') } );

    $('#motion-show').on('click', () => $('#motion-chart-div').toggle(this.checked))
    $('#in-out-show').on('click', () => $('#in-out-div').toggle(this.checked))
    $('#segmentation-show').on('click', () => $('#bee-count-segmentation-chart-div').toggle(this.checked))
    $('#subtraction-show').on('click', () => $('#bee-count-subtraction-chart-div').toggle(this.checked))
    $('#weight-show').on('click', () => $('#weight-chart-div').toggle(this.checked))
    $('#temperature-show').on('click', () => $('#temperature-chart-div').toggle(this.checked))
    $('#humidity-show').on('click', () => $('#humidity-div').toggle(this.checked))

});


function drawChart(duration = '1-week') {
    if (duration == 'last') {
        duration = duration_stash;
    } else {
        duration_stash = duration
    }

    let url;
    if (duration == "custom") {
        url = "/charts/motion.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/motion.json?duration=" + duration
    }


    $.getJSON(url, (jsonData) => {
        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])

        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('motion-chart-div'));
        chart.draw(data, options);
    });

    if (duration == "custom") {
        url = "/charts/motion/in_and_out.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/motion/in_and_out.json?duration=" + duration
    }

    $.getJSON(url, (jsonData) => {

        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])

        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('in-out-div'));
        chart.draw(data, options);

    });

    if (duration == "custom") {
        url = "/charts/bee_count/segmentation.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/bee_count/segmentation.json?duration=" + duration
    }

    $.getJSON(url, (jsonData) => {
        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])
        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('bee-count-segmentation-chart-div'));
        chart.draw(data, options);
    });

    if (duration == "custom") {
        url = "/charts/bee_count/subtraction.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/bee_count/subtraction.json?duration=" + duration
    }

    $.getJSON(url, (jsonData) => {
        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])
        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('bee-count-subtraction-chart-div'));
        chart.draw(data, options);
    });


    if (duration == "custom") {
        url = "/charts/weight.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/weight.json?duration=" + duration
    }
    $.getJSON(url, (jsonData) => {
        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])
        options['series'] = {
            0: { color: '#43459d', lineDashStyle: [2, 2] },
            1: { color: '#e2431e' },
            2: { color: '#1c91c0' }
        }
        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('weight-chart-div'));
        chart.draw(data, options);
    });

    if (duration == "custom") {
        url = "/charts/temperature.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/temperature.json?duration=" + duration
    }

    $.getJSON(url, (jsonData) => {
        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])
        options['series'] = {
            0: { color: '#43459d', lineDashStyle: [2, 2] },
            1: { color: '#e2431e' },
            2: { color: '#1c91c0' }
        }
        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('temperature-chart-div'));
        chart.draw(data, options);
    });

    if (duration == "custom") {
        url = "/charts/humidity.json?duration=" + duration + "&start_on=" + $("#start_on").val() + "&end_on=" + $('#end_on').val();
    } else {
        url = "/charts/humidity.json?duration=" + duration
    }

    $.getJSON(url, (jsonData) => {

        // Create our data table out of JSON data loaded from server.
        var data = new google.visualization.DataTable(jsonData['data']);

        var options = jsonData['options']
        options['hAxis']['minValue'] = new Date(options['hAxis']['minValue'])
        options['hAxis']['maxValue'] = new Date(options['hAxis']['maxValue'])
        options['series'] = {
            0: { color: '#43459d', lineDashStyle: [2, 2] },
            1: { color: '#e2431e' },
            2: { color: '#1c91c0' }
        }
        // Instantiate and draw our chart, passing in some options.
        var chart = new google.visualization.LineChart(document.getElementById('humidity-div'));
        chart.draw(data, options);


    });
}
